<template>
  <el-container>

    <el-header>
      <div class="title">作物监测系统</div>
      <!-- <div class="userInfo" v-if="user != ''"> -->
      <UserButton></UserButton>
      <!-- </div> -->
    </el-header>

    <el-container>
      <el-aside v-if="user != ''" :width="wth">
        <Aside ></Aside>
        <el-button class="menu-unfold" @click="collapse(false)" icon="el-icon-s-unfold" size="mini" v-if="isCollapse"></el-button>
        <el-button class="menu-fold" @click="collapse(true)" icon="el-icon-s-fold" size="mini" v-else></el-button>
      </el-aside>

      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <!-- <el-footer>
      Footer
     </el-footer> -->
  </el-container>
</template>

<script>
import { mapMutations } from 'vuex';
// @ is an alias to /src
import Aside from "./Aside.vue";
import UserButton from "./UserButton.vue";

export default {
  name: 'Home',
  data() {
    return {
      // wth: '210px',
      timer: null,
    }
  },
  mounted(){
    this.timer = setInterval(()=>{
      console.log("refresh")
    },5000)
  },
  components: {
    Aside, UserButton
  },
  computed: {
    wth(){
      if (!this.isCollapse){
        return '210px'
      }
      return '70px'
    },
    user() {
      if (this.$store.state.userid === null) {
        return ""
      }
      return this.$store.state.userid;
    },
    isCollapse(){
      return this.$store.state.menuCollapse
    }
  },
  methods:{
    ...mapMutations(["collapseMenu"]),
    collapse(val){
      this.collapseMenu(val)
    }
  }
}
</script>
<style scoped>
.el-header {

  background-color: #242f42;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}

.el-header .title {
  float: left;
  font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
  width: 250px;
  line-height: 70px;
}

.menu-unfold {
  position: absolute;
  left: 5px;
  bottom: 40px;
  background-color: #324157;

}

.menu-fold {
  position: absolute;
  left: 120px;
  bottom: 40px;
  background-color: #324157;

}

.el-footer {
  display: flex;
  height: 4vh;
  width: 100%;
  /* align-items: center; */
  background-color: #2c3e50;
  text-align: center;
}

.el-container {
  height: 100%;
}
.el-main {
  padding: 10px;
}
</style>
