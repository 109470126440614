import TileArcGISRest from 'ol/source/TileArcGISRest'
import OSM from "ol/source/OSM"
import XYZ from 'ol/source/XYZ'
import {
    Tile
} from 'ol/layer'
import TileLayer from 'ol/layer/WebGLTile';

import {
    Point
} from 'ol/geom'

import {
    Vector as LayerVec
} from 'ol/layer'
import {
    Feature
} from "ol";
import {
    Vector as SourceVec
} from 'ol/source'

export function createVector(name, lnglat, color) {
    // 创建矢量容器
    var vectorSource = new SourceVec({})
    //创建图标特性
    var iconFeature = new Feature({
        geometry: new Point(lnglat, "XY"),
        label: 'lng: ' + lnglat[0] + ", lat: " + lnglat[1]+", file: "+name,
    })
    console.log(color)
    //将图标特性添加进矢量中
    vectorSource.addFeature(iconFeature)
    //创建图标样式
    var iconStyle = new Style({
        image: new CircleStyle({
            radius: 5,
            // fill: new Fill({
            //     color: color,
            // }),
            stroke: new Stroke({
                color: color
            })
        })
    })
    //创建矢量层
    var vectorLayer = new LayerVec({
        source: vectorSource,
        style: iconStyle,
        name: name
    });
    return vectorLayer
}
import {
    View,
    Map
} from 'ol'

import LayerSwitcher from 'ol-layerswitcher';
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';

import LayerGroup from 'ol/layer/Group';
import {
    OverviewMap,
    ScaleLine,
    FullScreen
} from 'ol/control';
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style
} from 'ol/style';
import {
    LineString,
    Polygon
} from 'ol/geom';
import {
    getArea,
    getLength
} from 'ol/sphere';
import Draw from 'ol/interaction/Draw';
import {
    unByKey
} from 'ol/Observable';
import Overlay from 'ol/Overlay';
import {
    Control
} from 'ol/control';

import {
    GetCount
} from '../axios/axios.js';
const source = new SourceVec();

const vector = new LayerVec({
    name: 'draw',
    source: source,
    style: {
        'fill-color': 'rgba(255, 255, 255, 0.2)',
        'stroke-color': '#ffcc33',
        'stroke-width': 2,
        'circle-radius': 7,
        'circle-fill-color': '#ffcc33',
    },
});

/**
 * Currently drawn feature.
 * @type {import("../src/ol/Feature.js").default}
 */
let sketch;

/**
 * The help tooltip element.
 * @type {HTMLElement}
 */
let helpTooltipElement;

/**
 * Overlay to show the help messages.
 * @type {Overlay}
 */
let helpTooltip;

/**
 * The measure tooltip element.
 * @type {HTMLElement}
 */
let measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {Overlay}
 */
let measureTooltip;

/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
const continuePolygonMsg = 'Click to continue drawing the polygon';

/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
const continueLineMsg = 'Click to continue drawing the line';

/**
 * Handle pointer move.
 * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
 */
const pointerMoveHandler = function (evt) {
    if (evt.dragging) {
        return;
    }
    /** @type {string} */
    let helpMsg = 'Click to start drawing';

    if (sketch) {
        const geom = sketch.getGeometry();
        if (geom instanceof Polygon) {
            helpMsg = continuePolygonMsg;
        } else if (geom instanceof LineString) {
            helpMsg = continueLineMsg;
        }
    }

    helpTooltipElement.innerHTML = helpMsg;
    helpTooltip.setPosition(evt.coordinate);

    helpTooltipElement.classList.remove('hidden');
};

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
const formatLength = function (line) {
    const length = getLength(line, {
        projection: "EPSG:4326"
    });
    let output;
    if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
        output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = function (polygon) {

    const area = getArea(polygon, {
        projection: "EPSG:4326"
    });// unit: meter^2
    let output;
    // if (area > 10000) {
    if (area > 2000/3) {
        // output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup><br/>计数:';
        output = Math.round((area / 2000 * 3) * 100) / 100 + '' + '亩<br/>计数';
    } else {
        output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup><br/>计数:';
    }

    return output;
};

function addInteraction(map, measureType) {

    if (!measureType) {
        return
    }
    // map.removeLayer(vector)
    // map.addLayer(vector)
    map.getInteractions().pop()
    let draw = newDraw(measureType)
    map.addInteraction(draw);

    createMeasureTooltip(map);
    createHelpTooltip(map);

    let listener;
    draw.on('drawstart', function (evt) {
        // set sketch
        sketch = evt.feature;

        /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
        let tooltipCoord = evt.coordinate;

        listener = sketch.getGeometry().on('change', function (evt) {
            const geom = evt.target;
            let output;
            if (geom instanceof Polygon) {
                output = formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
            }
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
        });
    });

    draw.on('drawend', function (evt) {
        const geom = evt.feature.getGeometry();
        console.log(geom)
        if (geom instanceof Polygon) {
            let coordinates = geom.getCoordinates()[0]
            GetCount(coordinates).then(res => {
                let count = res.data.data.count
                measureTooltipElement.innerHTML += count
                measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
                measureTooltip.setOffset([0, -7]);
                // unset sketch
                sketch = null;
                // unset tooltip so that a new one can be created
                measureTooltipElement = null;
                createMeasureTooltip(map);
                unByKey(listener);
            })
        } else {
            measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
            measureTooltip.setOffset([0, -7]);
            // unset sketch
            sketch = null;
            // unset tooltip so that a new one can be created
            measureTooltipElement = null;
            createMeasureTooltip(map);
            unByKey(listener);
        }
    });
    map.on('pointermove', pointerMoveHandler);

    map.getViewport().addEventListener('mouseout', function () {
        helpTooltipElement.classList.add('hidden');
    });
}

/**
 * Creates a new help tooltip
 */
function createHelpTooltip(map) {
    if (helpTooltipElement) {
        helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement('div');
    helpTooltipElement.className = 'ol-tooltip hidden';
    helpTooltip = new Overlay({
        id: "measure-helptooltip",
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
    });
    map.addOverlay(helpTooltip);
}

function clearMeasure(map) {
    map.getInteractions().pop()
    if (measureTooltipElement) {
        measureTooltipElement = null
    }

    map.removeOverlay(map.getOverlayById("measure-tooltip"));
    map.removeOverlay(map.getOverlayById("measure-helptooltip"));
    // source.clear()
}
/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip(map) {
    if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    measureTooltip = new Overlay({
        id: "measure-tooltip",
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        stopEvent: false,
        insertFirst: false,
    });
    map.addOverlay(measureTooltip);
}

function newDraw(t) {
    return new Draw({
        source: source,
        type: t,
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
        }),
    })
}
class MeasureControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(map, opt_options) {
        const options = opt_options || {};

        const button1 = document.createElement('button');
        button1.innerHTML = 'L';

        const button2 = document.createElement('button');
        button2.innerHTML = 'A';
        const button3 = document.createElement('button');
        button3.innerHTML = 'N';
        const element = document.createElement('div');
        element.className = 'measure-length ol-unselectable ol-control';
        element.appendChild(button1);
        element.appendChild(button2);
        element.appendChild(button3);

        super({
            element: element,
            target: options.target,
            map: map,
        });

        button1.addEventListener('click', this.handleMeasureLength.bind(this), false);
        button2.addEventListener('click', this.handleMeasureArea.bind(this), false);
        button3.addEventListener('click', this.handleDisable.bind(this), false);
    }

    handleMeasureLength() {
        addInteraction(this.getMap(), 'LineString');
    }
    handleMeasureArea() {
        addInteraction(this.getMap(), 'Polygon');
    }
    handleDisable() {
        clearMeasure(this.getMap())
    }
}


export function initMap(mapcontainer) {
    let map = new Map({
        target: mapcontainer,
        layers: [
            new LayerGroup({
                title: "BaseLayers",
                layers: [
                    new Tile({
                        name: '天地图-影像',
                        title: '天地图-影像',
                        type: 'base',
                        visible: true,
                        source: new XYZ({
                            crossOrigin: 'anonymous',
                            url: 'https://t0.tianditu.gov.cn/img_w/wmts?' +
                                'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
                                '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=d54d890a963cb480ef94e861bb245134'
                        })
                    }),
                    new Tile({
                        name: 'OSM',
                        title: 'OSM',
                        type: 'base',
                        visible: false,
                        source: new OSM()
                    }),
                    new Tile({
                        name: 'ArcGis',
                        title: 'ArcGis',
                        type: 'base',
                        visible: false,
                        source: new TileArcGISRest({
                            url: 'https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer'
                        })
                    })

                ]
            }),
            new LayerGroup({
                title: "Overlays",
                layers: [
                    new Tile({
                        name: '天地图标注',
                        title: '天地图标注',
                        visible: true,
                        source: new XYZ({
                            crossOrigin: 'anonymous',
                            url: 'https://t0.tianditu.gov.cn/cia_w/wmts?' +
                                'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
                                '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=d54d890a963cb480ef94e861bb245134'
                        })
                    })
                ]
            }),
        ],
        view: new View({
            projection: "EPSG:4326", //使用这个坐标系
            center: [117.676498195142443, 36.576149052241924],
            zoom: 4
        })
    });
    var layerSwitcher = new LayerSwitcher({
        tipLabel: 'Légende', // Optional label for button
        groupSelectStyle: 'none' // Can be 'children' [default], 'group' or 'none'
    });
    map.addControl(layerSwitcher);

    const overviewMapControl = new OverviewMap({
        // see in overviewmap-custom.html to see the custom CSS used
        className: 'ol-overviewmap ol-custom-overviewmap',
        layers: [
            new TileLayer({
                // source: new OSM(),
                source: new XYZ({
                    crossOrigin: 'anonymous',
                    url: 'https://t0.tianditu.gov.cn/vec_w/wmts?' +
                        'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
                        '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=d54d890a963cb480ef94e861bb245134'
                }),
            }),
        ],
        collapseLabel: '\u00BB',
        label: '\u00AB',
        collapsed: true,
    });

    map.addControl(overviewMapControl)

    let control = new ScaleLine({
        units: 'metric',
    });

    map.addControl(control)

    map.on('loadstart', function () {
        map.getTargetElement().classList.add('spinner');
    });
    map.on('loadend', function () {
        map.getTargetElement().classList.remove('spinner');
    });
    map.addControl(new FullScreen({
        source: 'fullscreen',
    }))

    return map
}
export function addMesureCtl(map) {
    map.addControl(new MeasureControl())

    map.removeLayer(vector)
    map.addLayer(vector)
}

export function cleanDraw() {
    source.clear()
}