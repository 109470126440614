<template>
    <div>
        <BreadcrumbItem :breaditems="items"></BreadcrumbItem>
        <!-- <el-row> -->
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="用户ID">
                        <el-input type="text" v-model="this.$route.query.username" autocomplete="off" disabled>
                            {{ this.$route.query.username }}</el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row>
                <el-col :span="12">

                    <el-form-item label="昵称">
                        <el-input type="text" v-model="this.$route.query.username" autocomplete="off">
                            {{ this.$route.query.username }}</el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">

                    <el-form-item label="单位">
                        <el-input type="text" v-model="this.$route.query.username" autocomplete="off">
                            {{ this.$route.query.username }}</el-input>
                    </el-form-item>
                </el-col>
            </el-row> -->

        </el-form>
        <!-- </el-row> -->
    </div>
</template>

<script>
import BreadcrumbItem from '../common/BreadcrumbItem.vue';

export default {
    components: { BreadcrumbItem },
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('年龄不能为空'));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'));
                } else {
                    if (value < 18) {
                        callback(new Error('必须年满18岁'));
                    } else {
                        callback();
                    }
                }
            }, 1000);
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            items: ["我的","个人信息"],
            dialog: false,
            ruleForm: {
                pass: '',
                checkPass: '',
                age: ''
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                age: [
                    { validator: checkAge, trigger: 'blur' }
                ]
            }
        }
    }
}
</script>