<template>
    <div>
        <el-container>

            <el-main v-loading="mapLoading">
                <Map ref="map"></Map>
            </el-main>

            <el-aside width="65%">
                <BreadcrumbItem :breaditems="items"></BreadcrumbItem>

                <el-row>
                    <div class="process_type">
                        <router-link to="/data/set">
                            <el-button icon="el-icon-arrow-left" size="small"> 返回 </el-button>
                        </router-link>
                    </div>
                </el-row>
                <el-row class="buttonArr">
                    <!-- <el-button type="danger" size="small" icon="el-icon-delete"></el-button> -->
                    <el-button type="primary" size="small" @click="dialog = true">上传<i
                            class="el-icon-upload el-icon--right"></i></el-button>
                    <el-button type="success" plain size="small" icon="el-icon-refresh" @click="refreshData">刷新
                    </el-button>

                    <UploadData :dialog="dialog" @disableDialog="disableDialog" :dirpath="dirpath" :bucket="user">
                    </UploadData>
                </el-row>
                <ImageList :loading="tableLoading" :images="tableData" :bucket="user" @showImageLocation="showImageLocation"
                    @refresh="refreshData">
                </ImageList>
            </el-aside>
        </el-container>

    </div>
</template>
<style scoped>
.el-aside {
    padding: 10px;
    height: 100vh;
}

.el-main {
    padding: 10px;
    height: 100vh;
    /* top:0;
    left: 0;
    bottom: 0; */
    /* position: absolute; */

}

.buttonArr {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
}

.el-table {
    /* padding-top: 5px; */
    /* min-height: 700px; */
    /* margin: 2px; */
}


.process_type {
    float: left;

}
</style>
<script>
import Map from '../common/Map.vue'
import BreadcrumbItem from '../common/BreadcrumbItem.vue';
import UploadData from '../common/UploadData.vue';
// import { b2humanSize } from '../../utils/util';
import ImageList from '../common/ImageList.vue'
import { GetDataset } from '../../axios/axios';
export default {
    components: { BreadcrumbItem, ImageList, UploadData, Map },
    data() {
        return {
            dialog: false,
            tableLoading: false,
            mapLoading: false,
            loading: false,
            showViewer: false,
            tableData: [],
            url: '',
            formInline: {
                user: '',
                region: ''
            },
            map: null,
            popover: null,
        }
    },
    mounted() {

        this.refreshData()
    },
    computed: {
        items: {
            get() {
                return ["数据任务", "媒体库", this.$route.query.name]
            }
        },
        datasetId: {
            get() {
                return this.$route.params.id
            }
        },
        dirpath: {
            get() {
                return "datasets/" + this.datasetId + "/" + "raw"
            }
        },
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        },
    },
    methods: {
        disableDialog() {
            this.dialog = false
            this.refreshData()
        },
        goback() {
            this.$router.push({ name: "Dataset" })
        },

        refreshData() {
            this.tableLoading = true

            this.$refs.map.clearMarkers("Marker-")
            GetDataset(this.datasetId).then(
                (res) => {
                    this.tableData = res.data.data.objects
                    console.log(444444, this.tableData)
                    if (this.tableData) {

                        for (let i = 0; i < this.tableData.length; i++) {
                            this.setIcon({
                                name: this.tableData[i].name,
                                lng: this.tableData[i].lng,
                                lat: this.tableData[i].lat
                            }, false)

                        }
                    }
                    this.tableLoading = false
                    console.log(444444, this.tableData)
                }
            )


        },
        setIcon(row, focus) {
            this.$refs.map.setMarkerIcon(row, focus, "Marker-")
        },

        showImageLocation(row) {
            this.mapLoading = true
            this.$refs.map.clearMarkers("Marker-")
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].name === row.name) {
                    this.setIcon(row, true)
                } else {
                    this.setIcon(this.tableData[i], false)
                }
            }
            this.mapLoading = false

        }

    }
}
</script>