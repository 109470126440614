<template >
    <el-breadcrumb separator="/" separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item >首页</el-breadcrumb-item> -->
        <el-breadcrumb-item v-for="(val,i) in breaditems" :key="i">{{val}}
        </el-breadcrumb-item>
        <el-divider></el-divider>
    </el-breadcrumb>
</template>

<script>
export default{
    props: ["breaditems"]
}
</script>
<style scoped>
.el-breadcrumb{
    padding-bottom: 5px;
}
</style>