const axios = require('axios');

export   function  GetLocation(lng,lat) {

  return  axios.get("https://api.tianditu.gov.cn/geocoder", {
    params: {
      postStr: {
        lon: lng,
        lat: lat,
        ver: 1
      },
      type: "geocode",
      tk: "d54d890a963cb480ef94e861bb245134"
    }
  })
}