<template>
    <div>
        <el-container>

            <el-main v-loading="mapLoading">

                <Map ref="map"></Map>
                <div class="block">
                    <el-slider v-model="value2" :step="1" :format-tooltip="formatTooltip" :max="12" @change="refreshData">
                    </el-slider>
                </div>
                <div id="state-legend" class="legend">
                    <h4>作物</h4>
                    <div><span style="background-color: red"></span>较差</div>
                    <div><span style="background-color: yellow"></span>中等</div>
                    <div><span style="background-color: green"></span>较好</div>
                </div>
            </el-main>
            <el-aside>

                <BreadcrumbItem :breaditems="items"></BreadcrumbItem>
                <el-row>
                    <div class="process_type">
                        <router-link to="/data/set">
                            <el-button icon="el-icon-arrow-left" size="small"> 返回 </el-button>
                        </router-link>
                    </div>
                </el-row>
                <el-table ref="singleTable" v-loading="tableLoading" :data="tableData" height="700"
                    highlight-current-row>
                    <el-table-column prop="name" label="地区" width="100">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="item">
                            <el-button size="normal" type="text" @click="showImageLocation(item.row)">
                                定位</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-aside> 
        </el-container>

    </div>
</template>
<style scoped>
.el-aside {
    padding: 10px;
    height: 100vh;
}
.el-main {
    padding: 0px;
}
.block {
    width: 300px;
    /* background-color: #000; */
    border-radius: 3px;
    bottom: 20px;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
    color: #fff;
    /* font: 12px/200px 'Helvetica Neue', Arial, Helvetica, sans-serif; */
    /* padding: 10px; */
    position: absolute;
    left: 200px;
    z-index: 1;
}
.legend {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    bottom: 60px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 10px;
    position: absolute;
    left: 20px;
    z-index: 1;
}

.legend h4 {
    margin: 0 0 10px;
}

.legend div span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}
</style>
<script>
import BreadcrumbItem from '../common/BreadcrumbItem.vue';

import Map from '../common/Map.vue'
import {
    minioServer
} from '../../env';
export default {
    components: { BreadcrumbItem,Map },
    data() {
        return {
            items: ["其它功能", "分布图", this.$route.query.name],
            visible: false,
            map: null,
            tableData: [{ id: "heilongjiang", name: "黑龙江", url: "https://"+minioServer+"/geojson/heilongjiang/0701_0710.geojson" }],
            mapLoading: false,
            dialog: false,
            tableLoading: false,
            datasets: [],
            value2: 1,
        }
    },
    mounted() {
        this.$refs.map.ChoroplethMap()

        this.refreshData()

    },
    methods: {
        formatTooltip(val) {
            
        return 2010+val;
      },
        refreshData() {
            this.$refs.map.clearLayers("hlj")
            let row = {
                name: "hlj",
                url: "https://"+minioServer+"/geojson/heilongjiang/0701_0710.geojson"
            }
            this.$refs.map.addGeojsonLayer(row, "hlj")
            this.$refs.map.setLocation(131.92138572588983, 46.804727973665993, 11);
        },
        showImageLocation(row) {
            this.$message.info(row.url)
            this.$refs.map.setLocation(131.92138572588983, 46.804727973665993, 11);
        }
    }
}
</script>
