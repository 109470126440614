<template>
    <div>
        <el-container>

            <el-main v-loading="mapLoading">
                <Map ref="map"></Map>
            </el-main>

            <el-aside width="35%">
                <BreadcrumbItem :breaditems="items"></BreadcrumbItem>

                <el-row>
                    <div class="process_type">
                        <router-link to="/task">
                            <el-button icon="el-icon-arrow-left" size="small"> 返回 </el-button>
                        </router-link>
                    </div>
                </el-row>

                <el-table ref="singleTable" v-loading="tableLoading" :data="datasets" height="700" highlight-current-row>
                    <el-table-column prop="name" label="媒体库" width="60">
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <el-table ref="table2" :data="scope.row.objects" highlight-current-row>
                                <el-table-column label="图片">
                                    <template slot-scope="item">
                                        {{ item.row.name }}
                                        <el-image style="width: 40px; height: 40px" :src="item.row.jpg_url"
                                            :preview-src-list="[item.row.jpg_url]">
                                        </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量">
                                    <template slot-scope="item">
                                        {{ item.row.extra_data.pts_meta.count }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="item">
                                        <el-button size="mini" type="text" @click="showImageLocation(item.row)">
                                            定位</el-button>
                                            <el-button size="mini" type="text" @click="addPoints(item.row)">
                                            显示</el-button>
                                            <el-button size="mini" type="text" @click="removePoints(item.row)">
                                            隐藏</el-button>
                                        
                                    </template>
                                </el-table-column>
                                <el-table-column label="下载">
                                    <template slot-scope="item">

                                        <!-- <el-dropdown> -->
                                            <!-- <span class="el-dropdown-link">
                                                下载<i class="el-icon-arrow-down el-icon--right"></i>
                                            </span> -->
                                            <!-- <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item> -->
                                                    <el-button size="normal" type="text"
                                                        @click="handleDown(item.row.geotif_url)">
                                                        tif</el-button>
                                                <!-- </el-dropdown-item>
                                                <el-dropdown-item> -->
                                                    <el-button size="normal" type="text"
                                                        @click="handleDown(item.row.jpg_url)">
                                                        jpg</el-button>
                                                <!-- </el-dropdown-item>
                                                <el-dropdown-item> -->
                                                    <el-button size="normal" type="text"
                                                        @click="handleDown(item.row.pts_url)">
                                                        pts</el-button>
                                                <!-- </el-dropdown-item> -->
                                                <!-- <el-dropdown-item divided> -->
                                                    <el-button size="normal" type="text"
                                                        @click="handleDown(item.row.log_url)">
                                                        log</el-button>
                                                <!-- </el-dropdown-item> -->
                                            <!-- </el-dropdown-menu> -->
                                        <!-- </el-dropdown> -->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-table-column>
                </el-table>

            </el-aside>
        </el-container>
    </div>
</template>
<style scoped>
.el-aside {
    padding: 10px;
    height: 100vh;
}

.el-main {
    padding: 0px;
}
</style>

<script lang="js">

import BreadcrumbItem from '../common/BreadcrumbItem.vue';
import Map from '../common/Map.vue'

import { GetTaskById } from '../../axios/axios';
import { mapMutations } from 'vuex'
// import { Points } from '../../map/2000';
export default {
    components: { BreadcrumbItem, Map },
    data() {
        return {
            items: ["AI算法", "结果", this.$route.query.name],
            visible: false,
            map: null,
            tableData: [],
            mapLoading: false,
            dialog: false,
            tableLoading: false,
            datasets: [],
        }
    },
    mounted() {
        this.setTaskId(this.$route.query.id)
        this.refreshData()
        // this.setPoints()
    },

    methods: {
        ...mapMutations(['setTaskId']),
        addPoints(val) {
            let Points = val.extra_data.pts_meta.coordinates
            for (let i = 0; i < Points.length; i++) {
                let row = {
                    name: val.name+"-" + i,
                    lng: Points[i].lng,
                    lat: Points[i].lat,
                    filename: val.name
                }
                this.$refs.map.setMarkerIcon(row, true, "")
            }
        },
        removePoints(val) {
            console.log(val)
            let name = val.name+"-"
            this.$refs.map.clearLayers(name)

        },
        disableDialog() {
            this.dialog = false
            this.refreshData()
        },
        showImageLocation(val) {
            this.multipleSelection = val;
            console.log(val)

            this.$refs.map.setLocation(val.lng, val.lat, 18);
        },
        async refreshData() {
            this.tableLoading = true
            this.$refs.map.clearLayers("geotiff-")

            let datasetsres = await GetTaskById(this.$route.query.id)
            this.datasets = datasetsres.data.data.datasets

            for (let i = 0; i < this.datasets.length; i++) {
                let objsres = this.datasets[i].objects
                for (let j = 0; j < objsres.length; j++) {
                    console.log(objsres[j])

                    let row = {
                        name: objsres[j].name,
                        src: objsres[j].geotif_url,
                        lng: objsres[j].lng,
                        lat: objsres[j].lat,
                        extra_data: objsres[j].extra_data
                    }
                    this.$refs.map.addCOGLayer(row, "geotiff-")
                }
                this.$refs.map.MesureCtl()

            }

            this.tableLoading = false
            console.log(444444, this.datasets)
        },
        handleDown(url) {
            //window.location.href= process.env.VUE_APP_BASE_API + row.filePath;
            console.log('下载')
            let a = document.createElement('a')
            let path = url.lastIndexOf('/')
            // console.log(row.filePath.substr(path+1),"row.filePath.substr(path+1)")
            a.download = url.substr(path + 1)//设置下载的文件名
            // console.log(row.filePath.substr(path+1),"row.filePath.substr(path+1)")
            a.href = url; // 设置图片的下载地址
            a.target="_blank"
            a.click();//触发下载事件
        },
    },
    computed: {
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        }
    }
};
</script>