<template>
  <div class="aside">
    <el-menu router :default-active="$route.path" class="el-menu-vertical-demo" background-color="#324157"
      text-color="#bfcbd9" active-text-color="#20a0ff" :collapse="isCollapse" :collapse-transition="false">
      <el-menu-item index="/summary" style="text-align:left">
        <i class="el-icon-sunrise"></i>
        <span>总览</span>
      </el-menu-item>

      <el-submenu index="1">
        <template v-slot:title>
          <title>
            <slot name="title"></slot>
          </title>
          <i class="el-icon-orange"></i>
          <span>AI算法</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/data/set">媒体库</el-menu-item>
          <el-menu-item index="/task">任务</el-menu-item>
          <!-- <el-menu-item index="/result/taskresult"
            :route="{ path: '/result/taskresult', query: { id: latest.code, name: latest.name } }">任务结果</el-menu-item> -->
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2" v-if="usertype == 'developer'">
        <template v-slot:title>
          <title>
            <slot name="title"></slot>
          </title>
          <i class="el-icon-notebook-1"></i>
          <span>其它功能</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="/result/grid">COG渲染</el-menu-item> -->
          <el-menu-item index="/map/geojson">分布图</el-menu-item>

        </el-menu-item-group>
      </el-submenu> 


      <el-submenu index="3">
        <template v-slot:title>
          <title>
            <slot name="title"></slot>
          </title>
          <i class="el-icon-user"></i>

          <span>我的</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="/result/global">全局</el-menu-item>
              <el-menu-item index="1-2">地块</el-menu-item> -->
          <el-menu-item index="/userinfo" :route="{ path: '/userinfo', query: { username: user } }">个人信息
          </el-menu-item>
          <!-- <el-menu-item index="/password" :route="{ path: '/password' }">修改密码
          </el-menu-item> -->
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>
<style scoped>
* {
  font-size: medium;
}

.el-menu {
  /* width: 210px; */
  /* width: 100px!important; */
  /* min-height: 500px; */
  border-right: none;
}

.el-submenu {
  font-size: medium;
}

.el-menu-item {
  font-size: medium;
}

.el-menu-vertical-demo {
  /* width: 100%; */
  height: 100vh;
}

.el-submenu {
  text-align: left;
}

/* .el-menu el-submenu el-menu-item-group el-menu-item{
  width: 80px!important;
} */
</style>

<script>
import { GetTasks } from '../axios/axios'

export default {
  data() {
    return {
      latest: { name: "00000", id: "00000" },
    };
  },
  mounted() {
    GetTasks(this.$store.state.token, 1, 1).then(
      res => {
        if (res.data.data[0]) {
          this.latest = res.data.data[0]
        }
      }
    )
  },
  computed: {

    user() {
      if (this.$store.state.userid === null) {
        return ""
      }
      return this.$store.state.userid;
    },
    usertype() {
      return this.$store.state.usertype;
    },
    isCollapse() {
      return this.$store.state.menuCollapse
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

  }
}
</script>
