<template>
    <div>
        <el-table v-loading="loading" ref="singleTable" :data="images" height="700" @row-click="handleSelectionChange"
            highlight-current-row>
            <el-table-column type="index" width="55">
            </el-table-column>
            <el-table-column label="文件名" prop="name">
            </el-table-column>

            <el-table-column label="大小" prop="size">
                <template v-slot="{ row }">
                    {{ b2humanSize(row.size) }}
                </template>
            </el-table-column>
            <el-table-column label="坐标">
                <template v-slot="{ row }">
                    经度: {{ row.lng }}
                    <br />
                    纬度: {{ row.lat }}

                </template>
            </el-table-column>
            <el-table-column label="预览">
                <template slot-scope="item">
                    <el-image style="width: 40px; height: 40px" :src="item.row.preview_url"
                        :preview-src-list="[item.row.preview_url]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">

                    <!-- <div >
                        <el-button @click="onPreview(scope.row)" type="text">预览</el-button>
                    </div> -->
                    <div>
                        <a :href="scope.row.raw_url">下载</a>
                    </div>
                    <el-button size="normal" type="text" @click="deleteObj(scope.row.path)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[url]" />

    </div>
</template>
<style>
.el-table {
    height: 100%;
    /* padding-top: 5px; */
    /* min-height: 700px; */
    /* margin: 2px; */
}
</style>
<script>
import { isImage, b2humanSize } from '../../utils/util';
import { deleteObject } from '../../minio/minio';
import { GetLocation } from '../../geocoder/geocoder';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: { ElImageViewer },
    props: ["images", 'bucket', 'loading'],
    data() {
        return {
            dialog: false,
            // loading: false,
            showViewer: false,
            url: '',

            formInline: {
                user: '',
                region: ''
            }
        }
    },
    mounted() {

    },
    computed: {
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        }
    },
    methods: {
        isImage,
        b2humanSize,
        onPreview(img) {
            this.url = img.preview_url
            this.showViewer = true
        },
        // 关闭查看器
        closeViewer() {
            this.showViewer = false
        },
        onSubmit() {
            console.log('submit!');
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val)

            if (val) {
                GetLocation(val.lng, val.lat).then(res => {
                    console.log(res)
                    if (res.data == ""){
                        this.$message.error("位置: 未知")
                    }else{
                        this.$message.info("位置: " +res.data.result.formatted_address)
                    }
                }).catch(err => { this.$message.error(err)
                console.log(err)
                })
                this.$emit('showImageLocation', val)
            }


        },
        deleteObj(obj) {
            console.log(obj)
            deleteObject(this.bucket, obj)
            this.$emit('refresh')
        },

    }
}

</script>