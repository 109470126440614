import {Vector as LayerVec} from "ol/layer";
import {Vector as SourceVec} from "ol/source";
import { Style,Stroke } from "ol/style";

export function addChoroplethMap(map) {
    let highlight;

    const featureOverlay = new LayerVec({
        source: new SourceVec(),
        map: map,
        style: new Style({
            stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.5)',
                width: 1,
            }),
        }),
    });
    const displayFeatureInfo = function (pixel) {
        const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
            return feature;
        });

        //   const info = document.getElementById('info');
        //   if (feature) {
        //     info.innerHTML = feature.get('ECO_NAME') || '&nbsp;';
        //   } else {
        //     info.innerHTML = '&nbsp;';
        //   }

        if (feature !== highlight) {
            if (highlight) {
                featureOverlay.getSource().removeFeature(highlight);
            }
            if (feature) {
                featureOverlay.getSource().addFeature(feature);
            }
            highlight = feature;
        }
    };

    map.on('pointermove', function (evt) {
        if (evt.dragging) {
            return;
        }
        const pixel = map.getEventPixel(evt.originalEvent);
        displayFeatureInfo(pixel);
    });
}