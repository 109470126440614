  <template>
    <div>
        <Dataset v-if='queryid === undefined || queryid === ""'></Dataset>
        <!-- <DatasetDetail v-else></DatasetDetail> -->

    </div>

</template>
<style>
</style>
  <script>
import Dataset from './Dataset.vue';
// import DatasetDetail from './DatasetDetail.vue';
export default {
    components: { Dataset },
    data() {
        return {
            items: ["总览"],
            currentDate: new Date(),
            id: this.$route.query.id,

        }
    },
    computed: {
        queryid: {
            get() {
                return this.$route.query.id
            }
        }
    },
    methods: {
        gotodetail() {
            this.$router.push({ path: "/data/set/detail", query: { id: this.queryid } })
        }
    }
}
</script>