<template>
    <div>
        <BreadcrumbItem :breaditems="items"></BreadcrumbItem>
        <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm"
            width="200px">
            <el-row>
                <el-col :span="11">
                    <el-form-item label="旧密码" prop="age" :rules="[
                        { required: true, message: '年龄不能为空' },
                        { type: 'number', message: '年龄必须为数字值' }
                    ]">
                        <el-input type="age" v-model.number="numberValidateForm.age" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="11">

                    <el-form-item label="新密码" prop="age" :rules="[
                        { required: true, message: '年龄不能为空' },
                        { type: 'number', message: '年龄必须为数字值' }
                    ]">
                        <el-input type="age" v-model.number="numberValidateForm.age" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="11">

                    <el-form-item label="确认新密码" prop="age" :rules="[
                        { required: true, message: '年龄不能为空' },
                        { type: 'number', message: '年龄必须为数字值' }
                    ]">
                        <el-input type="age" v-model.number="numberValidateForm.age" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item>
                <el-button type="primary" @click="submitForm('numberValidateForm')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import BreadcrumbItem from '../common/BreadcrumbItem.vue';
export default {
    data() {
        return {
            items: ["我的", "修改密码"],
            numberValidateForm: {
                age: ""
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                }
                else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    components: { BreadcrumbItem }
}
</script>