import exifr from 'exifr'

export function exifRead(urlres) {
    return new Promise((resolve) => {

        exifr.parse(urlres, true).then(
            output => {
                console.log(output)
                resolve(output)
            }
        ).catch(
            (e) => {
                console.log(e)
                resolve({})
            }
        )
    })

}
