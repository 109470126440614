var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BreadcrumbItem',{attrs:{"breaditems":_vm.items}}),_c('el-form',{ref:"numberValidateForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.numberValidateForm,"label-width":"100px","width":"200px"}},[_c('el-row',[_c('el-col',{attrs:{"span":11}},[_c('el-form-item',{attrs:{"label":"旧密码","prop":"age","rules":[
                    { required: true, message: '年龄不能为空' },
                    { type: 'number', message: '年龄必须为数字值' }
                ]}},[_c('el-input',{attrs:{"type":"age","autocomplete":"off"},model:{value:(_vm.numberValidateForm.age),callback:function ($$v) {_vm.$set(_vm.numberValidateForm, "age", _vm._n($$v))},expression:"numberValidateForm.age"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":11}},[_c('el-form-item',{attrs:{"label":"新密码","prop":"age","rules":[
                    { required: true, message: '年龄不能为空' },
                    { type: 'number', message: '年龄必须为数字值' }
                ]}},[_c('el-input',{attrs:{"type":"age","autocomplete":"off"},model:{value:(_vm.numberValidateForm.age),callback:function ($$v) {_vm.$set(_vm.numberValidateForm, "age", _vm._n($$v))},expression:"numberValidateForm.age"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":11}},[_c('el-form-item',{attrs:{"label":"确认新密码","prop":"age","rules":[
                    { required: true, message: '年龄不能为空' },
                    { type: 'number', message: '年龄必须为数字值' }
                ]}},[_c('el-input',{attrs:{"type":"age","autocomplete":"off"},model:{value:(_vm.numberValidateForm.age),callback:function ($$v) {_vm.$set(_vm.numberValidateForm, "age", _vm._n($$v))},expression:"numberValidateForm.age"}})],1)],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('numberValidateForm')}}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }