<template>
    <div>
        <el-drawer title="新建媒体库" direction="rtl" :visible.sync="visible" size="50%" custom-class="demo-drawer"
            ref="drawer">
            <div class="demo-drawer__content">
                <el-form :model="form">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="名称" :label-width="formLabelWidth">
                                <el-input v-model="form.name" autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="物体类型" :label-width="formLabelWidth">
                                <el-select v-model="form.crop_id" placeholder="请选择">
                                    <el-option v-for="item in crops" :key="item.code" :label="item.name"
                                        :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="设备类型" :label-width="formLabelWidth">
                                <el-select v-model="form.device_id" placeholder="请选择">
                                    <el-option v-for="item in devices" :key="item.code" :label="item.name"
                                        :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item label="描述" :label-width="formLabelWidth">
                                <el-input type="textarea" rows="10" v-model="form.desc"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="demo-drawer__footer">
                    <el-button @click="cancelForm">取 消</el-button>
                    <el-button type="primary" @click="handleClose" :loading="loading">{{ loading ?
                    '提交中...' : '确 定'
                    }}</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { CreateDataset, GetCrops, GetDevices } from '../../axios/axios'
export default {
    props: ["dialog"],
    computed: {
        visible: {
            get: function () {
                return this.dialog

            },
            set: function () {
                return this.$emit('disableDialog')
            }
        }
    },
    mounted() {
        GetCrops().then(res => {
            this.crops = res.data.data
        })
        GetDevices().then(res => {
            this.devices = res.data.data
        })
    },
    data() {
        return {
            fileList: [{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }, { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }],
            formLabelWidth: '80px',
            timer: null,
            loading: false,
            crops: [],
            devices: [],
            form: {
                name: '',
                crop_id: '',
                desc: ''
            },
        }
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleClose() {
            if (this.loading) {
                return;
            }
            this.$confirm('确定要提交表单吗？')
                .then(() => {
                    this.loading = true;
                    CreateDataset(this.form.name, this.form.crop_id, this.form.device_id, this.form.desc).then(
                        res => {
                            console.log(res.data)
                            this.loading = false
                            this.$emit('disableDialog')
                        }
                    ).catch(err => {

                        console.log(err)
                    })
                })
                .catch(() => { });
        },
        handleExceed() {
            this.$message.warning('当前限制一个文件,请删除之后再试.')
        },
        cancelForm() {
            this.loading = false;
            this.$emit('disableDialog')
            clearTimeout(this.timer);
        }
    }
}
</script>

<style>
.demo-drawer__footer {
    margin-left: 10px;
}
</style>