<template>
    <div>
        <el-drawer title="上传数据" direction="rtl" :visible.sync="visible" size="50%" custom-class="demo-drawer"
            ref="drawer">
            <div class="demo-drawer__content">

                <el-form :model="form">
                    <el-row>

                        <el-col :span="20">
                            <el-form-item label="选择图片" :label-width="formLabelWidth">
                                <el-upload class="upload-demo" action="uploads3" :on-preview="handlePreview"
                                    :on-remove="handleRemove" :auto-upload="false" :on-change="handleChange" multiple
                                    accept=".jpg,.jpeg,.tiff,.png,.tif" list-type="picture" :on-exceed="handleExceed"
                                    :file-list="fileList" show-file-list>
                                    <el-button size="small" type="primary">点击选择</el-button>
                                    <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
                                </el-upload>

                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="20">
                            <el-form-item label="上传进度" :label-width="formLabelWidth">
                                <el-progress :text-inside="true" :stroke-width="24" :percentage="percentage"
                                    status="success"></el-progress>
                            </el-form-item>
                        </el-col>
                        <el-col :span="1">
                            {{ uploadCount }}/{{ uploadTotal }}
                        </el-col>
                    </el-row>



                </el-form>

                <div class="demo-drawer__footer">

                    <el-button @click="cancelForm" :disabled="loading">取 消</el-button>
                    <el-button type="primary" @click="handleClose" :loading="loading">{{ loading ?
                            '提交中...' : '确 定'
                    }}</el-button>
                </div>

            </div>
        </el-drawer>
    </div>
</template>
<script>
import { getUploadUrl, uploadByUrl } from '../../minio/minio'
// import { compressAccurately } from 'image-conversion';
// import { isImage } from '../../utils/util'
export default {
    props: ["dialog", "dirpath","bucket"],
    computed: {
        visible: {
            get: function () {
                return this.dialog

            },
            set: function () {
                return this.$emit('disableDialog')
            }
        },
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        }
    },
    data() {
        return {
            fileList: [],
            formLabelWidth: '80px',
            timer: null,
            loading: false,
            percentage: 0,
            uploadCount: 0,
            uploadTotal: 0,
            form: {
                name: '',
                croptype: '',
                croptime: '',
                desc: ''
            }
        }
    },
    methods: {
        handleRemove(file, fileList) {
            console.log("delete ", file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleChange(file, fileList) {
            console.log(fileList)
            this.fileList = fileList;
            this.uploadTotal = fileList.length
        },
        handleClose() {
            if (this.loading) {
                return;
            }

            console.log(this.fileList)
            this.loading = true;
            this.uploadCount = 0
            // this.uploadTotal = this.fileList.length
            for (let i = 0; i < this.fileList.length; i++) {
                console.log(this.fileList[i])
                // if (isImage(this.fileList[i].raw.type)&& this.fileList[i].raw.size > 1*1024*1024) {
                //     compressAccurately(this.fileList[i].raw, 200).then(compressres => {
                //         //The res in the promise is a compressed Blob type (which can be treated as a File type) file;
                //         console.log(compressres);
                //         getUploadUrl(this.user, this.datasetId + "/" + "thumb", this.fileList[i].name).then(
                //             (urlres) => {
                //                 console.log(urlres)
                //                 uploadByUrl(urlres, compressres).then(
                //                     () => {
                //                         console.log("upload compress")
                //                     }
                //                 )
                //             }
                //         )
                //     })

                // }

                getUploadUrl(this.bucket, this.dirpath, this.fileList[i].name).then(
                    (res) => {
                        console.log(res)
                        uploadByUrl(res, this.fileList[i].raw).then(
                            () => {
                                this.fileList[i].status = "success"
                                this.uploadCount++
                                if (this.uploadCount === this.fileList.length) {
                                    this.percentage = 100
                                } else {
                                    this.percentage = Number(((this.uploadCount) * 100 / this.fileList.length).toFixed(1))
                                }

                                if (this.percentage === 100) {
                                    this.timer = setTimeout(() => {
                                        // 动画关闭需要一定的时间
                                        setTimeout(() => {
                                            this.loading = false;
                                            this.fileList = []
                                            this.percentage = 0
                                            this.uploadCount = 0
                                            this.uploadTotal = 0
                                            this.$emit('disableDialog')
                                        }, 2000);
                                    }, 1000);

                                }
                            }
                        )
                    }
                )
            }


        },
        handleExceed() {
            this.$message.warning('当前限制一个文件,请删除之后再试.')
        },
        cancelForm() {
            this.loading = false;
            this.fileList = []
            this.$emit('disableDialog')
            clearTimeout(this.timer);
        }
    }
}
</script>

<style>
.el-form-item {
    margin: 2px;
}

.demo-drawer__footer {
    float: right;
    margin-left: 10px;
    padding: 40px;
}
</style>