<template>
    <div>
        <BreadcrumbItem :breaditems="items"></BreadcrumbItem>
        <el-row>
            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>存储空间</span>
                    </div>
                    <div class="text item">
                        媒体库: {{datasetsCount}} 个
                    </div>
                    <div class="text item">
                        图片文件: {{ datasetsFileCount }} 个
                    </div>
                    <div class="text item">
                        占用存储: {{datasetsStorageSize}}
                    </div>

                </el-card>
            </el-col>

            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>任务</span>
                    </div>
                    <div class="text item">
                        任务数 {{tasksCount}} 个
                    </div>
                    <div class="text item">
                        占用存储: {{tasksStorageSize}}
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>

</template>
<style>
.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.box-card {
    /* width: 480px; */
    height: 300px;
    padding: 5px;
    margin: 4px;
}
</style>
<script>
import BreadcrumbItem from '../common/BreadcrumbItem.vue';
import { getObjects } from '../../minio/minio.js'
import { b2humanSize } from '../../utils/util.js'
import { GetDatasets,GetTasks } from '../../axios/axios';
export default {
    components: { BreadcrumbItem },
    data() {
        return {
            items: ["总览"],
            currentDate: new Date(),
            datasetsFileCount: 0,
            datasetsFileSize: 0,
            tasksFileCount: 0,
            tasksFileSize: 0,
            datasetsCount: 0,
            tasksCount:0,
        }
    },
    mounted() {
        this.getFileCount()
        GetDatasets(this.$store.state.token, 1, 20).then(res => {
            this.datasetsCount = res.data.page_info.total
        })
        GetTasks(this.$store.state.token, 1, 20).then(res => {
            this.tasksCount = res.data.page_info.total
        })
    },
    methods: {
        getFileCount() {
            getObjects(this.user, "datasets").then(
                (objsres) => {
                    this.datasetsFileCount = objsres.length
                    for (let i = 0; i < objsres.length; i++) {
                        this.datasetsFileSize += objsres[i].size
                    }
                }
            ),
            getObjects(this.user, "tasks").then(
                (objsres) => {
                    this.tasksFileCount = objsres.length
                    for (let i = 0; i < objsres.length; i++) {
                        this.tasksFileSize += objsres[i].size
                    }
                }
            )
        }
    },
    computed: {
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        },
        datasetsStorageSize() {
            return b2humanSize(this.datasetsFileSize)
        },
        tasksStorageSize() {
            return b2humanSize(this.tasksFileSize)
        }
    }
}
</script>