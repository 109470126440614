<template>
    <div>
        <el-container>

            <el-main v-loading="mapLoading">
                <Map ref="map"></Map>
            </el-main>

            <el-aside v-loading="tableLoading">
                <BreadcrumbItem :breaditems="items"></BreadcrumbItem>

                <el-row>
                    <div class="process_type">
                        <router-link to="/data/set">
                            <el-button icon="el-icon-arrow-left" size="small"> 返回 </el-button>
                        </router-link>
                    </div>
                </el-row>
                <el-row class="buttonArr">
                    <!-- <el-button type="danger" size="small" icon="el-icon-delete"></el-button> -->
                    <el-button type="primary" size="small" @click="dialog = true">上传<i
                            class="el-icon-upload el-icon--right"></i></el-button>
                    <el-button type="success" plain size="small" icon="el-icon-refresh" @click="refreshData">刷新
                    </el-button>

                    <UploadData :dialog="dialog" @disableDialog="disableDialog" dirpath="" bucket="geotiff">
                    </UploadData>
                </el-row>
                <ImageList :images="tableData" bucket="geotiff" @showImageLocation="showImageLocation"
                    @refresh="refreshData">
                </ImageList>
            </el-aside>
        </el-container>
    </div>
</template>
<style scoped>
.el-aside {
    padding: 10px;
    height: 100vh;
}
.el-main {
    padding: 0px;
}
</style>

<script>

import BreadcrumbItem from '../common/BreadcrumbItem.vue';
import Map from '../common/Map.vue'
import { getPresignedGetObject, getObjectsWithMetadata } from '../../minio/minio.js'
import { b2humanSize } from '../../utils/util';

import { exifRead } from '../../utils/exifr'
import ImageList from '../common/ImageList.vue'
import UploadData from '../common/UploadData.vue';

export default {
    components: { BreadcrumbItem, ImageList, UploadData,Map },
    data() {
        return {
            items: ["其它功能", "COG渲染"],
            map: null,
            tableData: [],
            mapLoading: false,
            dialog: false,
            tableLoading: false
        }
    },
    mounted() {
        this.refreshData()
    },

    methods: {
        disableDialog() {
            this.dialog = false
            this.refreshData()
        },
        showImageLocation(val) {
            this.multipleSelection = val;
            console.log(val)
            this.$refs.map.clearLayers("geotiff-")
            this.$refs.map.addCOGLayer(val,"geotiff-")
            this.$refs.map.setLocation(val.lng, val.lat, 18);

        },

        async refreshData() {
            let objsres = await getObjectsWithMetadata("geotiff", "")

            this.tableLoading = true
            this.tableData = []

            for (let i = 0; i < objsres.length; i++) {
                console.log(objsres[i].metadata)
                let objurl = objsres[i].name

                let urlres = await getPresignedGetObject("geotiff", objurl)
                let pathNames = objsres[i].name.split('/')
                let exifdata = await exifRead(urlres)
                console.log(exifdata)
                console.log(exifdata.ModelTiePoint)
                let row = {
                    name: pathNames[pathNames.length - 1],
                    path: objsres[i].name,
                    src: urlres,
                    uploadtime: objsres[i].lastModified.Format('yyyy-MM-dd hh:mm:ss'),
                    size: b2humanSize(objsres[i].size),
                    type: objsres[i].metadata.Items.Value,
                    lng: 0,
                    lat: 0,
                    // createtime: objsres[i].lastModified.Format('yyyy-MM-dd hh:mm:ss')
                }
                if (exifdata.ModelTiePoint) {
                    row.lng = exifdata.ModelTiePoint[3] //X
                    row.lat = exifdata.ModelTiePoint[4] //Y
                }
                this.tableData.push(row)
                this.tableData = this.tableData.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                })

            }
            this.tableLoading = false
            console.log(444444, this.tableData)
        }
    }
};
</script>