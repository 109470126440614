<template>
    <div id="fullscreen" class="fullscreen">
        <div id="map" ref="rootmap" class="map">
            <div id="popup" class="ol-popup">
                <a href="#" id="popup-closer" class="ol-popup-closer"></a>
                <div id="popup-content" style="width:300px; height:120px;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { createVector, initMap, addMesureCtl, cleanDraw } from '../../map/geoserver';
import { Overlay } from 'ol'
import TileLayer from 'ol/layer/WebGLTile';
import GeoTIFF from 'ol/source/GeoTIFF';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import {addChoroplethMap} from '../../map/geojson'
export default {
    created() {
        this.cleanDraw()
    },
    mounted() {
        this.map = this.initMap(this.$refs.rootmap)
        this.popup()
    },

    methods: {
        initMap,
        addMesureCtl,
        cleanDraw,
        addChoroplethMap,
        popup() {
            /**
             * Elements that make up the popup.
             */
            var container = document.getElementById('popup');
            var content = document.getElementById('popup-content');
            var closer = document.getElementById('popup-closer');

            var overlay = new Overlay(/** @type {olx.OverlayOptions} */({
                element: container,
                autoPan: true,
                autoPanAnimation: {
                    duration: 250   //当Popup超出地图边界时，为了Popup全部可见，地图移动的速度. 单位为毫秒（ms）
                }
            }));
            /**
             * Add a click handler to hide the popup.
             * @return {boolean} Don't follow the href.
             */
            closer.onclick = function () {
                overlay.setPosition(undefined);
                closer.blur();
                return false;
            };
            this.map.addEventListener('click', (evt) => {
                const feature = this.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                    return feature;
                });
                if (!feature) {
                    return;
                }
                if (!feature.get('label')) {
                    return
                }
                var coordinate = evt.coordinate;
                content.innerHTML = '<p>你点击的坐标是：</p><code>' + feature.get('label') + '</code>';
                overlay.setPosition(coordinate);
                this.map.addOverlay(overlay);
            })
        },
        clearMarkers(namePrefix) {
            this.map.getLayers().getArray()
                .filter(layer => {
                    let n = layer.get('name')
                    if (n) {
                        return n.indexOf(namePrefix) != -1
                    }
                    return false
                })
                .forEach(layer => this.map.removeLayer(layer));
        },
        setMarkerIcon(row, focus, namePrefix) {

            if (!row || !row.lng) {
                return
            }
            let v
            if (focus) {
                v = createVector(namePrefix + row.name, [row.lng, row.lat], 'red')
            } else {
                v = createVector(namePrefix + row.name, [row.lng, row.lat], 'white')
            }

            this.map.addLayer(v);
            if (focus) {
                this.map.getView().setCenter([row.lng, row.lat]);
                // this.map.getView().setZoom(18)
            }
        },
        clearLayers(namePrefix) {
            this.map.getLayers().getArray()
                .filter(layer => {
                    let n = layer.get('name')
                    if (n) {
                        return n.indexOf(namePrefix) != -1
                    }
                    return false
                })
                .forEach(layer => this.map.removeLayer(layer));
        },
        addCOGLayer(row, namePrefix) {
            console.log(row)
            if (row.lng && row.lng != 0) {
                let layer = new TileLayer({
                    name: namePrefix + row.name,
                    source: new GeoTIFF({
                        sources: [{
                            url: row.src,
                        },],
                    }),
                })
                this.map.addLayer(layer)

            } else {
                this.$message.info("this image is not a COG geotiff file")
            }
        },
        addGeojsonLayer(row, namePrefix) {
            console.log(row, namePrefix)
            let color=''
            var styles = function (geojson) {
                if (geojson.get('gridcode') >= 30) { color = '#8c2d04'; }
                else if (geojson.get('gridcode') >= 3) { color = 'red'; }
                else if (geojson.get('gridcode') >= 2) { color = 'yellow'; }
                else if (geojson.get('gridcode') >= 1) { color = 'green'; }
                else if (geojson.get('gridcode') >= 0) { color = '#fdae6b'; }
                else { color = 'transparent'; }

                return [
                    new Style({
                        stroke: new Stroke({
                            color: 'black',
                            width: 0.7,
                        }),
                        fill: new Fill({
                            color: color,
                        }),
                    }),
                ]
            };
            let vectorLayer = new VectorLayer({
                name : namePrefix+row.name,
                // background: '#1a2b39',
                source: new VectorSource({
                    url: row.url,
                    format: new GeoJSON(),
                }),
                style: styles,
            });
            this.map.addLayer(vectorLayer)
        },
        setLocation(lng, lat, zoom) {
            this.map.getView().setCenter([lng, lat]);
            this.map.getView().setZoom(zoom)
        },
        // length area
        MesureCtl() {
            this.addMesureCtl(this.map)
        },
        // choropleth map
        ChoroplethMap(){
            this.addChoroplethMap(this.map)
        }
    }
}
</script>