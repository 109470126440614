<template>
    <div>
        <BreadcrumbItem :breaditems="items"></BreadcrumbItem>

        <el-row>
            <div class="process_type">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" disabled>
                    <el-form-item label="输入搜索">
                        <el-input v-model="formInline.user" placeholder="媒体库名称" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="物体类型">
                        <el-select v-model="formInline.region" placeholder="请选择" size="small">
                            <el-option v-for="item in crops" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="设备型号">
                        <el-select v-model="formInline.device" placeholder="请选择" size="small">
                            <el-option v-for="item in devices" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" circle @click="onSubmit" size="small"></el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-row>

        <el-row class="buttonArr">
            <!-- <el-button type="danger" size="small" icon="el-icon-delete"></el-button> -->
            <el-button type="primary" size="small" @click="dialog = true">新建</el-button>
            <el-button type="success" plain size="small" icon="el-icon-refresh" @click="init">刷新</el-button>
            <CreateDataset :dialog="dialog" @disableDialog="disableDialog"></CreateDataset>
        </el-row>
        <el-table ref="multipleTable" @selection-change="handleSelectionChange" :data="tableData" height="500"
            v-loading="tableLoading">
            <el-table-column label="媒体库">
                <template slot-scope="scope">
                    <el-button type="text" @click="gotodataset(scope.row.code, scope.row.name)">{{ scope.row.name }}
                    </el-button>
                </template>

            </el-table-column>
            <el-table-column label="物体类型" prop="crop.name">
            </el-table-column>
            <el-table-column label="设备类型" prop="device.name">
            </el-table-column>
            <el-table-column label="描述" prop="desc">
            </el-table-column>
            <el-table-column label="创建时间" prop="created_at">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">

                    <!-- <el-button size="normal" type="text">编辑</el-button> -->
                    <el-button size="normal" type="text" @click="deleteset(scope.row.code)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="pageChange" :current-page="currentPage" :page-size="pageSize"
            layout="total, prev, pager, next" :total="currentTotal">
        </el-pagination>
    </div>

</template>
<style scoped>
.el-form-item {
    margin-bottom: 1px;
}

.el-main {
    padding: 10px;
}
.buttonArr {
    padding: 5px;
}

.process_type {
    float: left;
}
</style>
<script>
import { GetDatasets, GetCrops, DelDataset, GetDevices } from '../../axios/axios';
import BreadcrumbItem from '../common/BreadcrumbItem.vue';
import CreateDataset from './CreateDataset.vue'
import { deleteAllObjects } from '../../minio/minio';
export default {
    components: { BreadcrumbItem, CreateDataset },
    data() {
        return {
            items: ["数据任务", "媒体库"],
            dialog: false,
            tableData: [],
            tableLoading: false,
            crops: [],
            devices: [],
            formInline: {
                user: '',
                region: ''
            },
            pages: {},
            currentPage: 1,
            currentTotal: 0,
            pageSize: 10

        }
    },
    mounted() {
        this.init()
    },
    computed: {
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        },
    },
    methods: {
        pageChange(val) {
            this.tableLoading = true
            GetDatasets(val, this.pageSize).then(res => {
                this.tableData = res.data.data
                this.pages = res.data.page_info
                this.tableLoading = false
                this.currentPage = val
            })
            this.tableLoading = false
        },
        init() {
            this.tableLoading = true
            GetDatasets(this.currentPage, this.pageSize).then(res => {
                console.log(res.data)
                this.tableData = res.data.data
                this.pages = res.data.page_info
                this.tableLoading = false
                this.currentTotal = this.pages.total
            })
            this.initCrops();
            GetDevices().then(res => {
                this.devices = res.data.data
            })
        },
        initCrops() {
            GetCrops().then(res => {
                this.crops = res.data.data
            })
        },

        onSubmit() {
            console.log('submit!');
        },
        disableDialog() {
            this.dialog = false
            this.init()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        gotodataset(code, name) {
            console.log(code)
            this.$router.push({
                path: "/data/set/" + code, query: {
                    name: name
                }
            })
        },
        deleteset(set_id) {
            deleteAllObjects(this.user, "datasets/" + set_id)
            DelDataset(set_id).then(res => {
                console.log(res.data)
                this.$message.success("删除成功")
                this.init()
            }).catch(
                err => {
                    console.log(err)
                    this.$message.error("删除失败")
                }
            )
        }
    }
}
</script>