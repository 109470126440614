<template>
    <el-container v-loading="loading">
        <el-main>
            <h4>图片对比</h4>

            <div class="demo-image__placeholder">
                <el-row>
                    <el-col :span="11">
                        <div class="block">
                            <span class="demonstration">原图</span>
                            <el-image :src="img.src" class="block" lazy :preview-src-list="[img.src,]"
                                v-if="isImage(img.type)"></el-image>
                            <el-image v-else>
                            </el-image>
                        </div>
                    </el-col>
                    <el-col :span="11">
                        <div class="block">
                            <span class="demonstration">识别图</span>
                            <el-image :src="img.src" class="block" lazy :preview-src-list="[img.src,]"
                                v-if="isImage(img.type)">
                            </el-image>
                            <el-image v-else>
                            </el-image>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-main>

        <el-aside>
            <BreadcrumbItem :breaditems="items"></BreadcrumbItem>

            <el-row>
                <div class="process_type">
                    <router-link to="/data/set">
                        <el-button icon="el-icon-arrow-left" size="small"> 返回 </el-button>
                    </router-link>
                </div>
            </el-row>
            <el-row class="buttonArr">
                <!-- <el-button type="danger" size="small" icon="el-icon-delete"></el-button> -->
                <el-button type="success" plain size="small" icon="el-icon-refresh" @click="refreshData">刷新
                </el-button>

            </el-row>
            <ImageList :images="tableData" @showImageLocation="showImageLocation" @refresh="refreshData">
            </ImageList>
        </el-aside>
    </el-container>
</template>
<style>
.block {
    margin: 4px;
}

。el-buttun {
    margin: 4px;
}
</style>
<script>
import BreadcrumbItem from '../common/BreadcrumbItem.vue';
import { getPresignedGetObject, getObjectsWithMetadata } from '../../minio/minio.js'
import { GetTaskById } from '../../axios/axios'
import { b2humanSize } from '../../utils/util';
import ImageList from '../common/ImageList.vue'
import { isImage } from '../../utils/util';
import { exifRead } from '../../utils/exifr'

export default {
    components: { BreadcrumbItem, ImageList },
    data() {
        return {
            items: ["结果查看", "任务", this.$route.query.name],
            task: { set_list: [] },
            tableData: [],
            loading: false,
            img: {
                type: "none"
            },
            options: {
                button: true
            }
        }
    },
    methods: {
        isImage,
        showImageLocation(img) {
            this.img = img
            console.log(img)
        },
        async refreshData() {
            this.loading = true
            // this.mapLoading = true
            this.tableData = []
            let tableCount = 0

            for (let j = 0; j < this.task.set_list.length; j++) {
                // this.loading = true
                // this.mapLoading = true
                let objsres = await getObjectsWithMetadata(this.user, "datasets/"+this.task.set_list[j].setid + "/" + "raw")

                if (objsres.length > 0) {
                    this.tableLoading = true
                }
                for (let i = 0; i < objsres.length; i++) {
                    console.log(objsres[i].metadata)
                    let objurl = objsres[i].name

                    let urlres = await getPresignedGetObject(this.user, objurl)
                    let pathNames = objsres[i].name.split('/')
                    let exifdata = await exifRead(urlres)
                    console.log(exifdata)
                    this.tableData.push({
                        name: pathNames[pathNames.length - 1],
                        path: objsres[i].name,
                        src: urlres,
                        uploadtime: objsres[i].lastModified.Format('yyyy-MM-dd hh:mm:ss'),
                        size: b2humanSize(objsres[i].size),
                        type: objsres[i].metadata.Items.Value,
                        lng: exifdata.lng,
                        lat: exifdata.lat,
                        // createtime: objsres[i].lastModified.Format('yyyy-MM-dd hh:mm:ss')
                    })
                    this.tableData = this.tableData.sort(function (a, b) {
                        return a.name.localeCompare(b.name)
                    })
                    if (tableCount == this.tableData.length) {
                        this.tableLoading = false
                    }

                }

            }
            console.log(444444, this.tableData)
            this.img = this.tableData[0]
            this.loading=false
        }
    },
    mounted() {

        GetTaskById(this.$store.state.token, this.$route.query.id).then(res => {
            this.task = res.data.data
            console.log(this.task)
            this.refreshData()
        }).catch(
            err => {
                console.log(err)
            }
        )
    },
    computed: {
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        }
    }
}
</script>