<template>
    <div>
        <el-drawer title="新建任务" direction="rtl" :visible.sync="visible" size="50%" custom-class="demo-drawer"
            ref="drawer">
            <div class="demo-drawer__content">
                <el-form :model="form">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="名称" :label-width="formLabelWidth">
                                <el-input v-model="form.name" autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="18">
                            <el-form-item label="任务类型" :label-width="formLabelWidth">
                                <el-select v-model="form.task_type_code" placeholder="请选择">
                                    <el-option v-for="item in task_class" :key="item.code" :label="item.name"
                                        :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="18">
                            <el-form-item label="设备类型" :label-width="formLabelWidth">
                                <el-input disabled :value="device_name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="18">
                            <el-form-item label="物体类型" :label-width="formLabelWidth">
                                <el-input  disabled :value="crop_name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="18">
                            <el-form-item label="阶段" :label-width="formLabelWidth">
                                <el-input disabled :value="growth_stage"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="18">
                            <el-form-item label="选择媒体库" :label-width="formLabelWidth">
                                <el-select v-model="form.sets" multiple placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.code" :label="item.name"
                                        :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="20">
                            <el-form-item label="任务描述" :label-width="formLabelWidth">
                                <el-input type="textarea" rows="10" v-model="form.desc"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </el-form>
                <div class="demo-drawer__footer">
                    <el-button @click="cancelForm">取 消</el-button>
                    <el-button type="primary" @click="handleClose" :loading="loading">{{ loading ?
                    '提交中...' : '确 定'
                    
                    
                    }}</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { GetDatasets, CreateTask,GetTaskClass,GetCrops,GetDevices } from '../../axios/axios'
export default {
    props: ["dialog"],
    computed: {
        crop_name:{
            get: function(){
                for (let i=0;i<this.task_class.length;i++){
                    if (this.task_class[i].code === this.form.task_type_code){
                        return this.task_class[i].crop.name
                    }
                }
                return ""

            }
        },
        device_name:{
            get: function(){
                for (let i=0;i<this.task_class.length;i++){
                    if (this.task_class[i].code === this.form.task_type_code){
                        return this.task_class[i].device.name
                    }
                }
                return ""
            }
        },
        growth_stage:{
            get: function(){
                for (let i=0;i<this.task_class.length;i++){
                    if (this.task_class[i].code === this.form.task_type_code){
                        return this.task_class[i].growth_stage.name
                    }
                }
                return ""

            }
        },
        visible: {
            get: function () {
                return this.dialog

            },
            set: function () {
                return this.$emit('disableDialog')
            }
        }
    },
    data() {
        return {
            fileList: [{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }, { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }],
            formLabelWidth: '80px',
            timer: null,
            loading: false,
            task_class:[],
            crops:[],
            devices:[],
            form: {
                name: '',
                task_type: {},
                croptype: '',
                croptime: '',
                desc: ''
            },
            options: [{
                value: '选项1',
                label: '媒体库1'
            }, {
                value: '选项2',
                label: '媒体库2'
            }, {
                value: '选项3',
                label: '媒体库3'
            }, {
                value: '选项4',
                label: '媒体库4'
            }, {
                value: '选项5',
                label: '媒体库5'
            }],
            value1: [],
        }
    },
    mounted() {
        GetDatasets(1, -1).then(res => {
            this.options = res.data.data
        })
        GetTaskClass().then(res=>{
            this.task_class= res.data.data
        })
        GetCrops().then(res => {
            this.crops = res.data.data
        })
        GetDevices().then(res => {
            this.devices = res.data.data
        })
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleClose() {
            if (this.loading) {
                return;
            }
            this.$confirm('确定要提交任务吗？')
                .then(() => {
                    this.loading = true;
                   let datasets =[]
                   for (let i=0;i<this.form.sets.length;i++){
                    datasets.push({code: this.form.sets[i]})
                   }
                    CreateTask(this.form.name, this.form.task_type_code,this.form.device_id,this.form.crop_id, datasets, this.form.desc).then(
                        res => {
                            console.log(res)
                            this.loading =false
                            this.$emit('disableDialog')
                        }
                    ).catch(err => {
                        console.log(err)
                    })
                })
                .catch(() => { });
        },
        handleExceed() {
            this.$message.warning('当前限制一个文件,请删除之后再试.')
        },
        cancelForm() {
            this.loading = false;
            this.$emit('disableDialog')
            clearTimeout(this.timer);
        }
    }
}
</script>

<style scoped>
.el-drawer {
    margin: 5px;
}

.demo-drawer__footer {
    padding: 5px;
    margin-left: 10px;
}
</style>